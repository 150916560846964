@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.light_theme .navbar-dark .navbar-nav .nav-link {
  color: black !important;
  font-weight: 500;
}
.btn.getstarted.small {
  min-width: 50px;
}

.nounder {
  text-decoration: none;
}

.input-group-text .dropdown-item:focus {
  background-color: var(--themeclr);
}

#loader_div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000000c7;
  z-index: 99999;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader img {
  -webkit-animation: loader;
          animation: loader;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }

  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
}

.transform-component-module_wrapper__1_Fgj {
  height: unset !important;
  width: unset !important;
  overflow: auto;
}

.colors {
  padding: 15px;
  /* background: #6c666691; */
  background: #000000 0% 0% no-repeat padding-box;
  max-width: 450px;
  position: absolute;
  /* top: 14px; */
  /* top: 100px; */
    /* left: 50px; */
  /* bottom: 40px; */
  bottom: 90px;
  right: 40px;
  z-index: 9;
  border: 1px solid var(--themebtncolor);
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.light_theme .colors {
  background: #4f862d 0% 0% no-repeat padding-box;
}
.colors p {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.colors .d-flex {
  grid-gap: 7px;
  gap: 7px;
}

.colors p span {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 2px;
  margin-left: 14px;
  margin-right: 2px;
}

.colors p span.avail {
  /* background-color: #f6df2d; */
  background-color: #dcc408;
}

.colors p span.wait {
  /* background-color: #c8ab06; */
  background-color: #BD9C19;
}

.colors p span.soon {
  /* background-color: #9b904f; */
  background-color: #4F862D;
}

.noshrink {
  flex-shrink: 0;
}

@media(max-width:575px) {
  .colors .d-flex {
    grid-gap: 2px;
    gap: 2px;
  }

  .colors {
    max-width: 372px;
    padding: 12px;
    left: 12px;


  }

  .colors p {
    font-size: 11px;
  }
}

@media(max-width:575px) {
  .colors {
    left: 5px;
    right: 5px;
  }
}

.latestarticle .token_items {
  min-height: 132px;
}

.latestarticle .token_items h2 {
  color: var(--themebtncolor);
  font-family: extrabold;
  padding: 8px 14px;
  margin-bottom: 0;
  margin-top: 14px;
  font-size: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 388px;
  white-space: nowrap;
}

.latestarticle .owl-nav {
  position: unset !important;
  width: 100%;
  top: 0;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.latestarticle .owl-nav .owl-prev {
  position: absolute;
  left: -6%;
  z-index: 2;
  top: 40%;
  background: #55464600 !important;
  color: #fff !important;
}

.latestarticle .owl-nav .owl-next {
  position: absolute;
  right: -6%;
  z-index: 2;
  top: 40%;
  background: #55464600 !important;
  color: #fff !important;

}

.carouselcontainer {
  max-width: 90%;
}

.latestarticle .owl-nav .owl-prev span,
.latestarticle .owl-nav .owl-next span {
  font-size: 52px;
}

.latestarticle a.col_token {
  height: 100%;
  display: block;
}

.latestarticle .token_list {
  height: 100%;
}

.latestarticle .owl-item {
  z-index: 1;
}

.latestarticle .owl-stage {
  /* display: flex; */
}

.carouselcontainer .token_list {
  margin-right: 0 !important;
}

.mapimg {
  background-image: url(/static/media/world.97e9b671.svg);
  width: 100vw;
  height: 100vh;
  background-repeat: repeat;
}




/* hari.css */
.land {
  padding-top: 6%;
}

.titles {
  color: var(--themetextclr);
}

.subtitles {
  color: var(--themebtncolor);
}

.memberlist {
  background: #05080D 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  max-width: 70rem;
  width: 100%;
  margin: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.light_theme .memberlist{
  background: #FAFAFA 0% 0% no-repeat padding-box;
}
.memberlist h1 {
  color: #E3B72C;
}
.light_theme .memberlist h1{
  color: black;
  font-weight: 600;
}
.light_theme .memberlist p{
  color: black;
}
.stepss {
  border: 1px solid #243d15;
  background-color: #4f862d;
  border-radius: 50%;
  /* padding: 10px; */
  display: flex;
  align-items: center;
  height: 50px;
  width: 65px;
  /* text-align: center; */
  justify-content: center;
  -webkit-animation: glow 2s infinite alternate;
          animation: glow 2s infinite alternate;
}
.light_theme .stepss{
  
}
.stepss2{
  width: 50px !important;
}
.stepss3{
  width: 170px !important;
}

@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #243d15;
  }

  to {
    box-shadow: 0 0 10px 10px #243d15;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px #243d15;
  }

  to {
    box-shadow: 0 0 10px 10px #243d15;
  }
}

.maxing {
  width: 100%;
  max-width: 90%;
  margin: auto;
}
.accordion-item button{
  background-color: #4F862D;
  color: #fff;
}
.accordion-item {
  border: 1px solid rgb(79 134 45);
}
.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #4f862d !important;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
}
.accordion-button::after {
  -webkit-filter: invert(1);
          filter: invert(1);
}
.accordion-item {
  background-color: black !important;
}
.light_theme .accordion-item {
  background-color: transparent !important;
  color: black;
  font-weight: 500;
}
.newsletter {
  padding: 10px 30px 22px 30px !important;
  max-width: 100rem !important;
}
.letter{
  color: var(--themebtncolor) !important;
  text-align: left !important;
  font-size: 26px;
}
.subletter{
  text-align: left !important;
}
.newsletter .form-control{
  background: #FFFFFF1A 0% 0% no-repeat padding-box;
  border: 1px solid #1e1e1e;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 14px;
}
.light_theme .newsletter .form-control{
  color: black !important;
}
.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(79 134 45 / 35%) !important;
}
.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgb(79 134 45 / 35%) !important;
}
.newsletter input::-webkit-input-placeholder{
  color: #fff !important;
  font-size: 14px;
}
.newsletter input::placeholder{
  color: #fff !important;
  font-size: 14px;
}
.light_theme .newsletter input::-webkit-input-placeholder{
  color: black !important;
}
.light_theme .newsletter input::placeholder{
  color: black !important;
}
.footer a{
  margin-left: 12px;
}
.footer img{
  max-width: 34px;
  /* border-radius: 50%; */
}
.footer img:hover{
  -webkit-filter: drop-shadow(2px 4px 8px #00800078);
          filter: drop-shadow(2px 4px 8px #00800078);
}
/* .maxing img{
  -webkit-animation: bounceRight 3s infinite;
  animation: bounceRight 3s infinite;
}@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    transform: translateX(-15px);
  }
} */
.react-read-more-read-less,.react-read-more-read-less-more{
  color: var(--themetextclr);
  font-weight: 500;
}
.themesub{
  color: var(--themebtncolor) !important;
}
.partner img{
  -webkit-filter: drop-shadow(2px 4px 4px #ffffffb5);
          filter: drop-shadow(2px 4px 4px #ffffffb5);
}
.light_theme .partner img {
  -webkit-filter: drop-shadow(2px 4px 4px #ffffffb5);
          filter: drop-shadow(2px 4px 4px #ffffffb5);
  background: #9e9e9e;
  border-radius: 18%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.partner img:hover{
  -webkit-filter: drop-shadow(2px 4px 4px #bd9c19a8);
          filter: drop-shadow(2px 4px 4px #bd9c19a8);
}
.stepss p {
  font-size: 24px !important;
  font-weight: 600 !important;
}
.smallsub{
  width: 100%;
    max-width: 40rem;
    margin: auto;
}
.stepno {
  color: var(--themebtncolor) !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}
.launchpads,.lockedstake{
  padding: 129px 0px 0px 0px;
}
.bgcolor{
    background-color: #5c9c34 !important;
}
@media only screen and (max-width: 1199px) {
  .maxing img {
    max-width: 40px;
  }
}

@media only screen and (max-width: 860px) {
  .maxing img {
    max-width: 30px;
  }

  .stepss {
    height: 42px;
    width: 100px !important;
  }
  .stepss3{
    width: 180px !important;
  }
  .stepss p {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .maxing img {
    display: none;
  }

  .stepss {
    height: 45px;
    width: 45px;
  }
  .stepss3 {
    width: 106px !important;
}
  .maxing .d-flex {
    /* justify-content: center; */
    justify-content: left;
    margin-bottom: 20px;
  }
}

@media(max-width:767px) {

  .latestarticle .owl-nav .owl-prev,
  .latestarticle .owl-nav .owl-next {
    position: unset;
  }

  .latestarticle .owl-nav {
    justify-content: center;
  }
}


@media only screen and (max-width: 575px){

  .newsletter .d-flex{
      display: unset !important;
  }
  .whatdoes .d-flex{
    display: unset !important;
  }
  .whatdoes a.ms-3{
    margin-left: 0px !important;
  }
  .newsletter button{
    margin-top: 10px;
  }
  .newletterrow{
    text-align: center !important;
  }
  .footer .textclr{
    font-size: 12px;
  }
}

@media only screen and (max-width: 350px){
.stepss {
    height: 45px;
    width: 60px;
}
.stepss3 {
  width: 200px !important;
}
}

  :root{
  --themeclr: #F6DF2D;
  --textclr:#DEC508;

  --themetextclr:#BD9C19;
  --themebtncolor:#4F862D; 
}
a{text-decoration: none !important;}
body{
  background-color: #000 !important;
  /* background-image: url(./Assets/images/bg.png); */
  color:#fff;
  /* font-family: medium; */
  font-family: 'Poppins', sans-serif;
}
body.light_theme {
  background-color: #fff !important;
}
body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: black;
}
.light_theme::-webkit-scrollbar-track{
  background-color: #fff !important;
}

body::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #BD9C19;
	background-color: var(--themetextclr);
}
.banner{
  position: relative;
  z-index: 4;
}
.okeyy{
  -webkit-transform: translatex(0);
          transform: translatex(0);
  -webkit-animation: 6s ease-in-out infinite float;
          animation: 6s ease-in-out infinite float;
  /* animation: mymove 5s infinite; */
}
@-webkit-keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}
@keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}
.nutsss{
  max-width: 5%;
  width: 100%;
  position: absolute;
  left: 8%;
  top: 12%;

}
@-webkit-keyframes float{
  0%, 100% {
    -webkit-transform: translatey(0);
            transform: translatey(0);
}

50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
}
}
@keyframes float{
  0%, 100% {
    -webkit-transform: translatey(0);
            transform: translatey(0);
}

50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px);
}
}

.nutsss1{
  max-width: 4%;
  width: 100%;
  position: absolute;
  left: 48%;
  top: 4%;
}
.nutsss2{
  max-width: 5%;
  width: 100%;
  position: absolute;
  left: 46%;
  bottom: 35%;
}
.nutsss3{
  max-width: 4%;
  width: 100%;
  position: absolute;
  left: 16%;
  bottom: 18%;
}
.nutssmall{
  max-width: 4%;
  width: 100%;
  position: absolute;
  right: 8%;
  top: 3%;
}
@font-face {
  font-family: themefont;
  src: url(/static/media/Gilroy-Black.3566c87f.ttf);
}
@font-face {
  font-family: thin;
  src: url(/static/media/Gilroy-Thin.28f856b7.ttf);
}
@font-face {
  font-family: bold;
  src: url(/static/media/Gilroy-Bold.ca49f765.ttf);
}
@font-face {
  font-family: extrabold;
  src: url(/static/media/Gilroy-ExtraBold.59a66eb8.ttf);
}
@font-face {
  font-family: medium;
  src: url(/static/media/Gilroy-Medium.1b4da658.ttf);
}
@font-face {
  font-family: normal;
  src: url(/static/media/Gilroy-Regular.5b89a8df.ttf);
}
@font-face {
  font-family: light;
  src: url(/static/media/Gilroy-Light.1822a291.ttf);
}
.container.container-theme {
  max-width: 95%;
}
.normal{
  font-family: normal;
}
.bold{
  font-family: bold;
  color: #BD9C19;
  color: var(--themetextclr);
}
.light_theme .bgtheme1 h3{
  color: #fff;
}
/* .blackarrowimg{
  display: none;
} */
.innerheading {
  font-family: extrabold;
}
.light_theme .innerheading{
  color: black;
}
.themeclr{
   color: #BD9C19 !important;
   color: var(--themetextclr) !important;
}
.textclr{
  /* color:var(--textclr); */
  color: #BD9C19;
  color: var(--themetextclr);
}
.textclr1{
  color: #BD9C19;
  color: var(--themetextclr);
  font-weight: 600;
}
.light_theme .textclr1{
  font-weight: 800;
}
.launchbox .textclr{
  color: #4F862D !important;
  color: var(--themebtncolor) !important;
  font-weight: 500;
}
.flex-1 > * {
  flex: 1 1;
}
.jc-between{
  justify-content: space-between;
}
.br-14{
  border-radius: 14px;
}
.bgtheme{
  background-color: #F6DF2D;
  background-color: var(--themeclr);
}
.bgtheme1{
  background: transparent;
  border: 1px solid #CBCBCB;
}
.light_theme .bgtheme1{
    background: #4F862D;
    background: var(--themebtncolor);
    border: 1px solid #CBCBCB;
}
button.ovalbtn {
  background: #4F862D;
  background: var(--themebtncolor);
  color: #fff !important;
  border-radius: 4px;
  padding: 4px 10px;
  font-weight: 500;
  border: 1px solid #4F862D;
  border: 1px solid var(--themebtncolor);
}
.btn.getstarted {
  background: #4F862D;
  background: var(--themebtncolor);
  color: #fff;
  /* font-family: 'extrabold'; */
  border-radius: 6px;
  /* position: relative;
  min-width: 120px; */
  padding: 6px 20px;
}
.btn.getstarted:hover{
  background: #BD9C19 !important;
  background: var(--themetextclr) !important;
}

.btn.getstarted1{
  border: 1px solid #4F862D;
  border: 1px solid var(--themebtncolor);
  color: #4F862D;
  color: var(--themebtncolor);
  background:transparent;
  border-radius: 6px;
  padding: 6px 20px;
}
.btn.getstarted1:hover{
  background: #BD9C19;
  background: var(--themetextclr);
  border: 1px solid #BD9C19;
  border: 1px solid var(--themetextclr);
  color: #fff;
}
.launchpad .launchbox .btn.getstarted,
.lockedstake .launchbox .btn.getstarted{
  background-color: #4F862D;
  background-color: var(--themebtncolor);
  color: #fff;
}
.launchpad .launchbox .btn.getstarted:hover{
  background-color: #BD9C19;
  background-color: var(--themetextclr);
  color: #fff;
}
.launchpad .innerheading, .lockedstake .innerheading{
  color: #000;
}
.smallp {
  max-width: 70%;
  margin: auto;
  color: #cbcbcb;
  font-size: 15px;
}
.light_theme .smallp{
  color: black;
  font-weight: 500;
}
button.chocobtn {
  /* background: var(--themeclr);
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 20%;
  
  font-weight: 700;
  border: 1px solid var(--themeclr); */
  background: #dd1f1f;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: 600;
  border: 1px solid #dd1f1f;
  color: #fff;
}
.dropdown-menu {
  background-color: black !important;
}
.light_theme .dropdown-menu{
  background-color: #fff !important;
}
.dropdown-item {
  color: #fff;
}
.light_theme .dropdown-item{
  color: black;
}
.dropdown-item:hover {
  color: #fff !important;
  background-color: #BD9C19 !important;
  background-color: var(--themetextclr) !important;
}
.usdtbtn{
  background: #BD9C19 !important;
  background: var(--themetextclr) !important;
  border: 1px solid #BD9C19 !important;
  border: 1px solid var(--themetextclr) !important;
  color: #fff !important;
}
.modal-header {
  border-bottom: 1px solid #bd9c197d;
}
.light_theme .modal-header h5{
  color: black;
}
.yellowbox p{
  margin-top: 18px;
  cursor: pointer;
}
.light_theme .yellowbox p {
color: black;
font-weight: 600;
}
.yellowbox  p:hover{
  color: #BD9C19;
  color: var(--themetextclr);
}
.livebtn{
  border-radius: 4px !important;
}
button.btn.getstarted.dark {
  background: #000;
  color: #fff;
}
/* button.btn.getstarted:after {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid white;
    position: absolute;
    left: -5px;
    bottom: 4px;
} */
section {
  margin-top: 65px;
  margin-bottom: 65px;
}
/* button.btn.getstarted:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid white;
  position: absolute;
  left: -5px;
  bottom: 4px;
  transition: 0.2s;
} */
.ban-cont p, .features-list p {
  /* font-family: normal;
  color: #CBCBCB;
  line-height: 2; */
  color: #fff;
  font-weight: 500;
  /* font-weight: 300; */
  font-size: 15px;
}
.light_theme .ban-cont p {
  color: black;
  font-weight: 500;
}
.light_theme .numberstep{
  color: #fff !important;
}
.light_theme .features-list p {
  color: black;
  font-weight: 500;
}
.light_theme .blackarrowimg{
  -webkit-filter: invert(1);
          filter: invert(1);
}
.btn.btn-theme{
  background-color: #DEC508;
  background-color: var(--textclr);
}

/* header */
/* .land .navbar-nav .getstarted {
  display: none;
} */
.header .bg-dark {
  background: #000 !important;
  border-bottom: 1px solid #bd9c19a1;
  position: fixed;
    width: 100%;
    top: 0;
    z-index: 777;
}
.light_theme .header .bg-dark{
  background: #fff !important;
}
.header .navbar-nav a:hover,
.header .navbar-nav a.nav-link.active {
  border-bottom: 2px solid #BD9C19;
  border-bottom: 2px solid var( --themetextclr);
  color: #BD9C19 !important;
  color: var(--themetextclr) !important;
}
.header .navbar-nav a {
  border-bottom: 3px solid transparent;
  margin-left: 20px;
  margin-right: 20px;
}
.header .navbar-nav a:last-child{
  margin-right: 0;
}
.header .navbar-nav a:first-child{
  margin-left: 0;
}
.lockedstake nav.navbar .btn.getstarted,
.launchpad nav.navbar .btn.getstarted {
  max-width: 150px;
}
/* tabs */
ul.nav-tabs {
  max-width: 435px;
  flex-flow: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
}
ul.nav-tabs li{
  /* flex: 1; */
  flex: inherit;
  white-space: nowrap;
}
li.nav-item button {
  background: transparent !important;
  border-color: transparent !important;
  color: #ffffff8c;
  width: 100%;
}
.light_theme li.nav-item button {
  color: black;
  font-weight: 500;
}
li.nav-item button:hover{
  color: #fff !important;
}
.light_theme li.nav-item button:hover {
  color: #4F862D !important;
  color: var(--themebtncolor) !important;
}
li.nav-item button.active {
  color: #fff !important;
  background-color: #4f862d !important;
  border-radius: 5px;
}
ul.nav-tabs{
  border: 0 !important;
}


.news{position: relative;}
/* .newshead{margin-top:-24px;} */
.news .items{position: relative;height: 90vh;}
.news .itemsfles{    display: flex;
  align-items: start;
  flex-direction: column;
  max-height: 100vh;
  justify-content: center;
  width: 50%;
  height: 100%;
  min-height: 80vh;
  position: absolute;
  bottom: 0;
  left: 41px;
  right: 0;
  top: 0;
}
  .news .itemsfles h2{font-size: 50px;font-weight: 600;}
  .news .itemsfles p{font-size: 16px;font-weight: 400;}
  .news .owl-carousel .owl-nav{position: absolute;    bottom: 50px;
    right: 80px;}
    .news .owl-carousel .owl-nav button.owl-next{
      /* background-color: #ffffff80;
      color: #5e5e5e; */
      background-color: #4f862d;
      color: #fff;
      font-size: 28px;

      border-radius: 50px;
      width: 42px;}
      .light_theme .news .owl-carousel .owl-nav button.owl-next {
        background-color: #4f862d !important;
    }
    .light_theme .news .owl-carousel .owl-nav button.owl-prev{ background-color: #4f862d !important;
}
    .news .owl-carousel .owl-nav button.owl-prev{background-color: #ffffff80;color: #5e5e5e;
      font-size: 28px;

      border-radius: 50px;
      width: 42px;}
      .news .owl-carousel .owl-nav button.owl-prev:hover,.news .owl-carousel .owl-nav button.owl-next:hover{background-color: #fff;
        color: black;
      }
      .Editor_pick{cursor: pointer;}
      .Editor_pick h2{color: #fff;    
        /* font-family: extrabold; */
        font-weight: 500;
        padding: 30px 0;}
        .light_theme  .editss{color: black; font-weight: 600;   }
      .col_token:nth-child(even) .token_list{background-color: transparent;border: 1px solid #4F862D;border: 1px solid var(--themebtncolor);border-radius: 12px;margin-top: 20px;transition: all 0.5s ease;margin-bottom: 6px;}
      .token_list{background-color: transparent;border: 1px solid #4F862D;border: 1px solid var(--themebtncolor);border-radius: 12px;margin-top: 20px;transition: all 0.5s ease;margin-bottom: 6px;
        transition: all .3s ease;
      }
     .light_theme .col_token:nth-child(even) .token_list {
      background: #F7F9FB 0% 0% no-repeat padding-box;
    }
      .light_theme .token_list{
        background: #F7F9FB 0% 0% no-repeat padding-box;
      }
      .token_list:hover{
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
      }
      .tokenimg{display: flex;align-items: center;justify-content: center;border-radius: 5px;min-height: 200px;max-height: 200px;overflow: hidden;}
      .tokenimg img{min-height: 70px;object-fit: cover;max-width: 100%;transition: transform .5s ease,-webkit-transform .5s ease;}
      .tokenimg img:hover{
        -webkit-transform: scale(1.1);
                transform: scale(1.1); 
      }
      .token_items h2{color: #4F862D;color: var(--themebtncolor);font-family: extrabold;padding: 0px 14px 8px 14px;margin-bottom: 0; font-size: 21px;}
      .token_items p{color: #fff;padding: 8px 14px;font-size: 14px;margin-bottom: 0;    font-weight: 300;}
      .light_theme  .token_items p{color: black;font-weight: 500;font-size: 15px;}
     .launchpad .token_list {
        background-color: #DEC508;
        border: 1px solid #DEC508;
        border-radius: 5px;
        margin-top: 20px;
        transition: all 0.5s ease;
        margin-bottom: 6px;
        margin-right: 8px;
    }
    .launchpad .col_token .token_list {
      background-color: transparent;
      border: 1px solid #4F862D;
      border: 1px solid var(--themebtncolor);
      border-radius: 5px;
      margin-top: 20px;
      transition: all 0.5s ease;
      margin-bottom: 6px;
      margin-right: 8px;
  }
    .launchpad .tokenimg{display: flex;align-items: center;justify-content: center;border-radius: 5px;min-height: 250px;max-height: 250px;overflow: hidden;
      /* padding: 0; */
      margin-top: 0px;
      padding: 0px 0px;
    }
      .launchpad .tokenimg img{min-height: 250px;object-fit: cover;max-width: 100%; border-radius: 0px;}
     .newdetail {display: flex;align-items: center;justify-content: center;min-height: 100vh;max-height: 100vh;overflow: hidden;cursor: pointer;}
     .newdetail img{min-height: 100vh;max-width: 100%;object-fit: cover;min-width: 100%;}
     .newdetailsec{background-color: transparent;margin-top: -100px;
     border: 1px solid #4F862D;
     border: 1px solid var(--themebtncolor);border-radius: 8px;
    }
     .recent_post{background-color: transparent;margin-top: 50px;padding: 20px;border: 1px solid #4F862D;border: 1px solid var(--themebtncolor);border-radius: 6px;}
     .vdieo_hide{border: 1px solid #eee;    padding: 0px 40px 40px 40px;
      text-align: center;}
     .form_loist{background-color: #fff;margin-top: 50px;padding: 20px;}
     .newdetailsec h2{color: #4F862D;color: var(--themebtncolor);font-size: 38px;
      padding: 30px 30px 10px 30px;
      background-color: #000000ba;
      margin-top: 2px; 
  }
     .recent_post h2{color: #BD9C19;color: var(--themetextclr);font-size: 18px;font-weight: 600;
     padding-bottom: 10px;
  }
     .form_loist h2{color: #000;font-size: 30px;font-weight: 600;
     padding-bottom: 10px;
  }
  .imgdive{  display: flex;padding: 0 5px 40px 5px ; 
    font-size: 0;
    flex-flow: row wrap;justify-content:center;
    grid-gap: 1px;
    gap: 1px;
    max-width: 800px;
    margin: 0 auto;}
      .imgs{  margin: 0; min-width: 201px;
    flex: 0.67 1; transition: .5s;display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 250px;
    max-height: 250px;}
      .imgs img{max-width: 100%;min-height: 250px;object-fit: cover;}
  .recentblogdet{display: flex;align-items: center;justify-content: start;margin-bottom: 20px;}
  .recent_post a{color: #fff !important;text-decoration: none;font-size: 16px;font-weight: 400;margin-left: 20px;transition: all 0.5s ease;}
 .light_theme .recent_post a{color: black !important;font-weight: 500;}
  .recent_post a:hover{color: #BD9C19 !important;color: var(--themetextclr) !important;text-decoration: none;font-size: 16px;font-weight: 400;margin-left: 20px;}
  .recent_post img{width: 80px;height: 70px;object-fit: cover;}
  .dayscmment{display: flex;align-items: center;padding: 0px 0px 0px 30px;cursor: pointer;}
  .dayscmment p{color: #818181;font-size: 14px;margin-right: 10px;}
  .newdetailsec .textconete{color: #fff;font-size: 14px;margin-right: 10px;padding: 0px 30px 30px 30px;font-weight: 300;}
  .dayscmment i{color: #818181;font-size: 14px;}

  .light_theme .newdetailsec .textconete {
    color: black;
    font-size: 15px;
    font-weight: 500;
}
/* banner */

.heading{
  font-size: 55px;
}
.light_theme .heading{
  color: black;
}
.bannerimg img {
  width: 100%;
  max-width: 500px;
}

/* feature */
.features-list {
  border: 1px solid transparent;
  border-radius: 14px;
}
.light_theme .features-list h4{
  color: black;
  font-weight: 600;
}
.features-list:hover {
    border: 1px solid #4F862D;
    border: 1px solid var(--themebtncolor);
}

/* roadmap */
h6.roadmapbtn:after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background: white;
  top: -31px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.light_theme h6.roadmapbtn:after {
  background: black;
}
h6.roadmapbtn {
  position: relative;
}
.line:after {
  content: "";
  height: 22px;
  width: 22px;
  background: #4F862D;
  display: block;
  color: #F6DF2D;
  color: var(--themeclr);
  position: absolute;
  border-radius: 50%;
  border: 4px solid #AA8E1E;
  left: 0;
  right: 0;
  margin: auto;
  top: 26%;
  z-index: 1;
  -webkit-animation: glow1 2s infinite alternate;
          animation: glow1 2s infinite alternate;
}
@-webkit-keyframes glow1 {
  from {
    box-shadow: 0 0 10px -10px #aa8e1ea3;
  }

  to {
    box-shadow: 0 0 10px 10px #aa8e1ea3;
  }
}
@keyframes glow1 {
  from {
    box-shadow: 0 0 10px -10px #aa8e1ea3;
  }

  to {
    box-shadow: 0 0 10px 10px #aa8e1ea3;
  }
}

.car-item:after {
  width: 100%;
  content: "";
  height: 9px;
  background: #4F862D;
  background: var(--themebtncolor);
  position: absolute;
  left: 0;
  right: 0;
  /* top: 26%; */
  top:29%;
}

.fullinerelative{
  position: relative;
}
.fullline {
  position: absolute;
  height: 6px;
  width: 100%;
  background: #2D2D2D;
  top: 68px;
  border-radius: 10px;
}
.roadmap .owl-item:first-child .car-item:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.logogrid{display: grid;grid-template-columns: repeat(4 , 1fr);grid-gap: 50px;gap: 50px;    place-items: center;}
.logogrid img{    width: 10vw;
  max-height: 3vw;}
.notyetstart .line:after {
  background: #F6DF2D;
  background: var(--themeclr);
  border-color: #817E7E;
  border-width: 3px;
}
.owl-carousel .owl-nav button.owl-next:hover ,
.owl-carousel .owl-nav button.owl-prev:hover {
  background: transparent;
}
.notyetstart:after {
  background: transparent;
}
.inprogress:after {
  width: 50%;
}
.car-item h6:first-child {
  margin-bottom: 66px;
}

.car-item h6:first-child {
  margin-bottom: 66px;
}
.car-item{
  text-align: center;
  position: relative;
  width: 100%;
}
.car-item p {
  color: #CBCBCB;
  font-size: 15px;
  padding-right: 15px;
  min-height: 50px;
}
.infos p , .marketcapital  p{
  font-size: 16px;
  color: #fff;
}
.roadmap .owl-nav button span {
  margin-top: 0 !important;
}
.car-item .line + h6 {
  margin-top: 70px;
}
.car-item h6 {
  color: white;
}
h6.roadmapbtn {
  background: #4F862D;
  background: var(--themebtncolor);
  max-width: 120px;
  padding: 7px;
  border-radius: 20px;
  color: #fff;
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 11px;
}
.slider_sec_1  img{
  max-width: 170px;
  margin:20px auto;
  transition: all 0.5s ease;
  cursor: pointer;
  /* max-height: 110px;
  min-height: 110px; */
}

.slider_sec_1 .owl-carousel .owl-nav .owl-prev span,.slider_sec_1 .owl-carousel .owl-nav .owl-next span{
  /* display: none; */
  color: #97afd5;
}
.slider_sec_1 .owl-carousel .owl-nav .owl-prev span, .slider_sec_1 .owl-carousel .owl-nav .owl-next span {
  color: #97afd5;
  border: 1px solid #97afd5;
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  font-size: 35px;
  font-weight: 100;
  line-height: 0.8;
  margin-top: 40px;
}

.slider_sec_1 .owl-item
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 150px !important;
}
.slider_sec_1 .owl-item div,.slider_sec_1 .owl-item div a
{
    height: 100%;

}
.slider_sec_1 .owl-item .car-item > div{
  padding-top: 40px;
}

.light_theme .car-item p{
  color: black;
  font-weight: 500;
}


/* launchpad */
.bannerbox .innerheading {
  font-size: 45px;
  text-shadow: 0px 8px 2px #5f59592b;
}
.bordbox {
  border: 1px solid #fff;
}
.light_theme .bordbox {
  border: 1px solid #0000009c;
}
.lightapr{
  color: #BD9C19;
  color: var(--themetextclr);
  font-weight: 500;
}
.light_theme .light-p{
  color: black;
  font-weight: 600;
}
.launchbox {
  background-color: black;
  /* border: 1px solid #313131; */
  border: 1px solid #ffffff80;
  position: relative;
}
.light_theme .launchbox {
  background-color: transparent;
  border: 1px solid #4F862D;
  border: 1px solid var(--themebtncolor);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}


/* .launchpad button.chocobtn {
  position: absolute;
  right: 24px;
  top: -17px;
} */
.moonlight{
  /* background-color: white;
  margin-left: 12px;
  padding: 0px 10px!important;
  font-size: 18px; */
   background-color: black;
    margin-left: 12px;
    padding: 0px 10px!important;
    font-size: 18px;
    border: 1px solid #aa8e1e;
    border-radius: 4px;
}
.light_theme .moonlight{
  background-color: #fff !important;
}
.progress {
  background-color: #272727;
  height: 10px;
  margin-top: 15px;
}
.progress-bar {
  background: #4F862D;
  background: var(--themebtncolor);
  border-radius: 5px;
}

.launchbox  h6 {
  /* font-family: 'bold'; */
}
.light_theme .launchbox h6 {
 color: black;
}
.light_theme .yieldvalue{
  color: black;
  font-weight: 500;
}
.logocont img {
  /* border: 2px solid var(--themeclr); */
  border-radius: 50%;
  height: 45px;
  width: 45px;
  padding: 5px;
  object-fit: contain;
}
.logocont span {
  text-transform: uppercase;
  font-family: normal;
}
.launchpad .bgtheme .innerheading{
  text-shadow: 0px 8px 2px #5f59592b
}
/* .light_theme .launchpad .bgtheme .innerheading{
 color: #fff;
} */
.newshead{
  padding: 25px 0px 0px 0px;
}
.light_theme .launchpad .innerheading {
  color: #fff;
}
.light_theme .lockedstake .innerheading{
  color: #fff;
}
.light_theme .bgtheme p{
  color: #fff;
}
.light_theme .unstake{
  color: black;
}
.calcicon {
  width: 12px;
}


/* modal */
.modal-backdrop.show {
  opacity: .9;
}
.modal-content {
  background: #0F0F0F;
} 
.light_theme .modal-content{
  background-color: #fff;
}
.modal-backdrop {
    background-color: black;
}
.yellowbox {
  border: 1px solid #ffffffab;
  background: #000000;
  border-radius: 15px;
}
.light_theme .yellowbox {
  border: 1px solid #4F862D;
  border: 1px solid var(--themebtncolor);
  background: transparent;
}
.bold1{
    margin-top: 12px;
}
.gruntitle{
  color: #BD9C19;
  color: var(--themetextclr);
  font-weight: 600;
}
.themeclr1{
  color: #4F862D;
  color: var(--themebtncolor);
  font-size: 17px;
  font-weight: 600;
}
.light_theme .themeclr1{
  color: #4F862D !important;
  color: var(--themebtncolor) !important;
}
.bannerbox{
  min-height: 181px;
}
.modal .yellowbox.connect.text-center {
  max-width: 185px;
  margin: auto;
}
.modal .yellowbox img {
  max-width: 50px;
  height: auto;
}
.modal-sm {
  max-width: 400px;
  margin: auto;
}
.btn-link.close:hover{
  color: #F6DF2D;
  color: var(--themeclr);
}
.input-group input {
  background: #000 !important;
  border: none;
  border-radius: 8px;
  color: #fff;
  min-height: 48px;
}
.light_theme .input-group input {
  background: #fff !important;
}
.input-group.h-75 input{
  height: 75px;
}
span.input-group-text {
  background: #000;
  border: none;
  border-radius: 8px;
}
.light_theme span.input-group-text {
  background: #fff;
}
.input-group {
  border: 1px solid #BD9C19;
  border: 1px solid var(--themetextclr);
  border-radius: 8px;
}
.input-group input:focus {
  box-shadow: none;
}
.form_loist label{color: #5e5e5e;font-size: 12px;}
.labelforme{display: flex;align-items: start;justify-content: center;flex-direction: column;    width: 100%;}
.form_loist .labelforme textarea{--webkit-appearance:none !important;-webkit-appearance: none !important;appearance: none !important;border: 1px solid #5e5e5e33 !important;margin-top: 10px;width: 98%;}
.form_loist .labelforme textarea:focus{outline: none;}
.form_loist .labelforme input[type="text"]{--webkit-appearance:none !important;-webkit-appearance: none !important;appearance: none !important;border: 1px solid #5e5e5e33 !important;
  max-width: 95%;
  min-width: 80%;    height: 40px;
  width: 100%;}
  .labelformecheck{display: flex;align-items: center;
    margin-bottom: 30px;
}
  .submitfomer{
    background-color: #F6DF2D;
    font-size: 14px;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    font-weight: 500;
    padding:12px 14px !important;
    height: 50px;
    margin-top: 30px;
    border: 1px solid #F6DF2D;
transition: all 0.5s ease;
  }
  .vdieo_hide iframe{    max-width: 100%;
    width: 550px;
    max-height: 300px;
    height: 300px;
    min-height: 300px;}
  .submitfomer:hover{background-color:transparent;color: #000;}
.labelformecheck p{color: #5e5e5e;font-size: 14px;margin-bottom: 0;margin-left: 10px;}
.form_loist .labelforme input:focus{outline: none;}
.inputfoe{display: flex;align-items: center;justify-content: space-between;margin-top: 10px;}
/* footer */
.footer {
  border-top: 1px solid #bd9c19a1;
  padding-top: 25px;
  padding-bottom: 10px;
  margin-top: 54px;
}
.footer .textclr{
  /* font-family: light; */
  font-weight: 500;
}
.moonlight:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 13%) !important;
}
.footer ul li{
  list-style-type: none;
  display: inline-block;
}
.footer ul li{
  margin-left: 10px;
  margin-right: 10px;
}
.footer ul li:first-child{
  margin-left: 0;
}
.footer ul li span {
  color: #DEC508;
  color: var(--textclr);
  font-size: 25px;
}
.light_theme .navbar-toggler {
  background-color: #bd9c19;
  border: 1px solid #bd9c19;
}
.form-control:focus {
  color: #ffff !important;
}
.light_theme .form-control:focus {
  color: black !important;
}
.stepbtn{
    font-size: 26px;
    font-weight: 600;
    border-radius: 50%;
    background: #4F862D;
    background: var(--themebtncolor);
    color: #fff;
    padding: 10px 26px;
    -webkit-animation: glow 2s infinite alternate;
            animation: glow 2s infinite alternate;
    transition: 0.5s;
}

.stepbtn:hover{
  color: #fff;
}
.stepbtn1{
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
  border: 1px solid #4F862D;
  border: 1px solid var(--themebtncolor);
  background: transparent;
  color: #fff;
  padding: 12px 25px;
  -webkit-animation: glow 2s infinite alternate;
          animation: glow 2s infinite alternate;
  transition: 0.5s;
}
.stepbtn1:hover{
  background: #4F862D;
  background: var(--themebtncolor);
  color: #fff;
  transition: 0.5s;
}
.react-transform-wrapper {
  padding-top: 4%!important;
}
.light_theme .stepbtn1:hover{
   color: #fff;
   transition: 0.5s;
}
.light_theme .stepbtn1{
  color: #4F862D;
  color: var(--themebtncolor);
  -webkit-animation: glowing 2s infinite alternate;
          animation: glowing 2s infinite alternate;
}
.light_theme .stepbtn{
  -webkit-animation: glowing 2s infinite alternate;
          animation: glowing 2s infinite alternate;
}
@-webkit-keyframes glowing {
0% {
  box-shadow: 0 0 10px -10px #243d154a;
}
100% {
  box-shadow: 0 0 10px 10px #243d154a;
}
}
@keyframes glowing {
0% {
  box-shadow: 0 0 10px -10px #243d154a;
}
100% {
  box-shadow: 0 0 10px 10px #243d154a;
}
}
/* .cicon{
  animation: glowingc 2s infinite alternate;
}
.teleicon{
  animation: glowingtele 2s infinite alternate;
}
.whatsappicon{
  animation: glowingwhat 2s infinite alternate;
}
@keyframes glowingc {
  0% {
    box-shadow: 0 0 10px -10px #ffffff59;
}
100% {
    box-shadow: 0 0 10px 10px #ffffff59;
}
}
@keyframes glowingtele {
  0% {
    box-shadow: 0 0 10px -10px #039be585;
}

100% {
    box-shadow: 0 0 10px 10px #039be585;
}
}
@keyframes glowingwhat {
  0% {
    box-shadow: 0 0 10px -10px green;
}
100% {
    box-shadow: 0 0 10px 10px green;
}
} */
/* .lockedstake .row .lockedstackcont:nth-child(1),.lockedstackcont:nth-child(3),.lockedstackcont:nth-child(4),.lockedstackcont:nth-child(6){
  display: none;
} */
.cryptoo{
  color: #BD9C19 !important;
  color: var(--themetextclr) !important;
}

.fa-moon {color: #f1c40f;}

.fa-sun {color: #f39c12;}





@media(min-width:1601px){

  .car-item p{
    min-height: 78px;
}
.line:after{
    top:23%;
}
 
}





@media(max-width:1199px){
  .smallp {
    max-width: 100%;
  }
  .news .itemsfles{min-height: 60vh;}
  .news .itemsfles h2{font-size: 40px !important;}
  
.news .items{    height: 60vh;}
.news .owl-carousel .owl-nav{bottom: 50px;}
}

@media only screen and (min-width: 992px) and (max-width: 1014px)  {
  .header .navbar-nav a {
    margin-left: 15px;
    margin-right: 15px;
  }
 
  }

@media(max-width:992px){.news .itemsfles h2{font-size: 40px;}
.news .itemsfles {
  min-height: 50vh;
} 
}
@media(max-width:991px){
  .moonlight{
    max-width: 18%;
    margin-top: 12px;
    margin-left: 0px;
  }
  .navbar-collapse{
          position: absolute;
    background: black;
    width: 100%;
    top: 87px;
    left: 0px;
    padding-left: 40px;
    padding-bottom: 20px;
    padding-top: 10px;
}
.light_theme .navbar-collapse {
  background: #fff;
  border: 1px solid #4f862d;
  border-radius: 12px;
}
.navbar-collapse .btn.getstarted {
  width: 100%;
  max-width: 80%;
}
.news .items{    height: 90vh;background-size: 100%;}
.news .itemsfles {

  width: 100%;
}
.news .itemsfles p{width: 80%;}
.news .itemsfles h2{font-size: 36px;}
  .navbar-collapse a{
        max-width: -webkit-max-content;
        max-width: max-content;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .bannerbox .innerheading {
    font-size: 30px;
  }
  .header .navbar-nav a{
    margin: 0;
  }
  .heading {
    font-size: 40px;
}
.bannerimg img {
  max-width: 390px;
}
}
@media(max-width:767px){
  .stepbtn {
    font-size: 20px;
    padding: 8px 18px;
  }
  .stepbtn1 {
    font-size: 20px;
    padding: 8px 18px;
  }
  .howdoes img{
    display: none;
  }
  .infos p, .marketcapital p {
    font-size: 16px;
}

.news .owl-carousel .owl-nav {
  bottom: 0px;
}
.news .itemsfles {
  min-height: 41vh;
}
.news .items {
  height: 90vh;
  background-size: 100% 100%;
}
.navbar-brand img {
  height: 40px;
}

}
.logos_sec{max-width: 80%;margin: 0 auto;}
@media(max-width:575px){
  .news .itemsfles {
    left: 31px;
}
  .colors {
    bottom: 250px;
}
  .react-transform-wrapper {
    padding-top: 40%!important;
  }
  .newdetailsec{
  margin-top: -60px;
}
.logogrid{
  grid-template-columns: repeat(3,1fr);
}
.stepbtn {
  font-size: 20px;
  padding: 8px 18px;
}
.stepbtn1 {
  font-size: 20px;
  padding: 8px 18px;
}

}
@media(max-width:425px){
  .slider_sec_1 img {
      max-width: 99px;
      margin: 0px auto;
  }
  footer {text-align: center !important;margin: 0 auto !important;}
  .newdetail{min-height: unset;max-height: unset;}
  .newdetail img{min-height: unset;}
  .items .newdetail img{min-height: 100vh;}
  .news .itemsfles p{font-size: 14px;}
  .newdetailsec h2{font-size: 30px;}
  .connect p {
    font-size: 12px;
  }
  .news .itemsfles h2 {
    font-size: 24px !important;
}
  .news .owl-carousel .owl-nav button.owl-next,.news .owl-carousel .owl-nav button.owl-prev{font-size: 22px;     width: 32px;}
  .news .owl-carousel .owl-nav{left: 0;right: 0;}
}

@media(min-width:1800px){
  .heading{
    font-size: 75px;
  }
}

.news .Editor_pick.firstsec .token_list{
  height: 100%;
}
.newdetailsec h2 {
  font-weight: 600;
}
.newdetailsec .textconete b{
  font-size: 16px;
  color: #BD9C19;
  color: var(--themetextclr);
}
.launchpad .noticelink{
  color: #838383 !important;
  font-size: 18px;

}
.launchpad .menu_list{
 position:relative;
}
.launchpad .notice_link{
  position:absolute;
  right: 0px;
  top: -30px;
}

/* .Editor_pick.firstsec .col_token{
  height: 100%;

} */
